var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('dv-full-screen-container',{staticClass:"bg"},[_c('Header'),_c('Left'),_c('transition',{attrs:{"name":"el-fade-in"}},[(_vm.show)?_c('Info'):_vm._e()],1),_c('div',{staticClass:"page-action",class:_vm.rightShow ? 'right-leave' : 'right-enter'},[_c('Right',{attrs:{"lists":_vm.firmLists}})],1),_c('Footer'),_vm._l((_vm.companyList),function(item,idx){return _c('div',{key:idx,staticClass:"div",class:idx == 0
        ? 'company1'
        : idx == 1
        ? 'company2'
        : idx == 2
        ? 'company3'
        : idx == 3
        ? 'company4'
        : idx == 4
        ? 'company5'
        : idx == 5
        ? 'company6'
        : 'company7',on:{"click":function($event){$event.stopPropagation();return _vm.handleCompany(idx, item)}}},[_c('span',[_vm._v(_vm._s(item.name))]),_c('p',{directives:[{name:"show",rawName:"v-show",value:(_vm.active == idx),expression:"active == idx"}]},[_c('img',{attrs:{"src":require("@/assets/home/fang.png"),"alt":""}})])])}),_vm._l((_vm.companyList),function(item,indx){return [_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.active == indx),expression:"active == indx"}],key:indx + 'i',staticClass:"positionName",class:indx == 0
          ? 'position1'
          : indx == 1
          ? 'position2'
          : indx == 2
          ? 'position3'
          : indx == 3
          ? 'position4'
          : indx == 4
          ? 'position5'
          : indx == 5
          ? 'position6'
          : 'position7'})]}),_c('Dialog',{attrs:{"info":_vm.firmList},on:{"open":_vm.handleGs,"close":_vm.handleCloseFirm}})],2)}
var staticRenderFns = []

export { render, staticRenderFns }