<template>
  <div class="firm" v-if="showInfo" >
    <i @click="handleClose">
      <img src="@/assets/close-2.png" alt="" />
    </i>
    <ul>
      <li
        v-for="(item, index) in info"
        :key="index"
        @click="handleGs(item.companyId)"
        :class="cAct == item.companyId ? 'act' : ''"
      >
        {{ item.companyName }}
        <img class="rt" src="@/assets/home/right.png" alt="" />
      </li>
      <!-- <li>{{ item.companyName }}</li> -->
    </ul>
  </div>
</template>

<script>
export default {
  props: ["info", "value"],
  data() {
    return {
      showInfo: false,
      show1: false,
      show2: false,
      show3: false,
      taskList: [],
      cAct: ""
    };
  },
  created() {},
  filters: {
    warrantyStatus(val) {
      switch (val) {
        case 1:
          return "已过保";
        case 2:
          return "质保时间内";
        case 3:
          return "无";
      }
    },
  },
  mounted() {
    //点击其他区域关闭下拉菜单
    document.addEventListener("click", (e) => {
      if (!this.$el.contains(e.target) && e.target.className != "iconImg") {
        this.handleClose()
      }
    });
  },
  watch: {
    info(val) {
      this.showInfo = true;
    },
  },
  methods: {
    handleGs(id) {
      this.cAct = id
      this.$emit('open',id)
    },
    handleClose() {
      this.showInfo = false
      this.$emit('close')
    },
    handleClick1() {
      this.show1 = !this.show1;
      if (this.show1) {
        this.show2 = false;
        this.show3 = false;
      }
    },
    handleClick2() {
      this.show2 = !this.show2;
      if (this.show2) {
        this.getEquipmenyTask();
      }
      if (this.show2) {
        this.show1 = false;
        this.show3 = false;
      }
    },
    handleClick3() {
      this.show3 = !this.show3;
      if (this.show3) {
        this.show2 = false;
        this.show1 = false;
      }
    },
    async getEquipmenyTask() {
      let data = {
        equipmentId: this.info.equipmentId,
      };
      let res = await this.$api.device.getEquipmenyTask(data);
      if (res.code == 200) {
        this.taskList = res.data.dataList;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.firm {
  position: absolute;
  top: 5%;
  left: calc(50% - 2.7rem);
  width: 5.4rem;
  height: 4.05rem;
  background-image: url("../../assets/home/firm.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  z-index: 2000;
  i {
    position: absolute;
    right: 0.1rem;
    top: 0.1rem;
    width: 0.4rem;
    height: 0.4rem;
    cursor: pointer;
    img {
      width: 0.4rem;
    }
  }
  ul {
    margin-left: 0.1rem;
    margin-top: 0.5rem;
    margin-right: 0.12rem;
    height: 3.3rem;
    overflow: auto;
    /* 滚动条样式 */
    /*定义滚动条高宽及背景 高宽分别对应横竖滚动条的尺寸*/
    &::-webkit-scrollbar {
      width: 4px;
      height: 10px;
      //   background-color: #00d8ff;
    }

    /*定义滚动条轨道 内阴影+圆角*/
    &::-webkit-scrollbar-track {
      // -webkit-box-shadow: inset 0 0 6px #08101d;
      // border-radius: 10px;
      background-color:transparent;
    }

    /*定义滑块 内阴影+圆角*/
    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      // -webkit-box-shadow: inset 0 0 6px #152847;
      background-color: #00d8ff;
    }
    li {
      font-size: 0.25rem;
      color: #fff;
      height: 0.7rem;
      width: 100%;
      line-height: 0.7rem;
      background-image: url("../../assets/home/firms.png");
      background-size: 100% 100%;
      padding-left: 0.3rem;
      margin-bottom: 0.1rem;
      cursor: pointer;
      position: relative;
      transition: all 0.3s;
      img {
        position: absolute;
        right: 0.2rem;
        width: 0.2rem;
        height: 0.2rem;
        top: calc(50% - 0.1rem);
      }
      &.act {
        color: #00d8ff;
      }
    }
  }
}
</style>