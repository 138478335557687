<template>
  <dv-full-screen-container class="bg">
    <Header></Header>
    <Left />
    <transition name="el-fade-in">
      <Info v-if="show" />
    </transition>
    <div class="page-action" :class="rightShow ? 'right-leave' : 'right-enter'">
      <Right :lists="firmLists" />
    </div>
    <Footer></Footer>
    <div
      class="div"
      @click.stop="handleCompany(idx, item)"
      v-for="(item, idx) in companyList"
      :key="idx"
      :class="
        idx == 0
          ? 'company1'
          : idx == 1
          ? 'company2'
          : idx == 2
          ? 'company3'
          : idx == 3
          ? 'company4'
          : idx == 4
          ? 'company5'
          : idx == 5
          ? 'company6'
          : 'company7'
      "
    >
      <span>{{ item.name }}</span>
      <p v-show="active == idx">
        <img src="@/assets/home/fang.png" alt="" />
      </p>
    </div>
    <!-- <div
      class="firm"
      v-if="firmShow"
      style=""
    >
      <i @click="handleCloseFirm">
        <img src="@/assets/close-2.png" alt="" />
      </i>
      <ul>
        <li
          v-for="(item, index) in firmList"
          :key="index"
          @click="handleGs(item.companyId)"
          :class="cAct == item.companyId ? 'act' : ''"
        >
          {{ item.companyName }}
          <img class="rt" src="@/assets/home/right.png" alt="" />
        </li>
      </ul>
    </div> -->
    <template v-for="(item, indx) in companyList">
      <div
        :key="indx + 'i'"
        class="positionName"
        :class="
          indx == 0
            ? 'position1'
            : indx == 1
            ? 'position2'
            : indx == 2
            ? 'position3'
            : indx == 3
            ? 'position4'
            : indx == 4
            ? 'position5'
            : indx == 5
            ? 'position6'
            : 'position7'
        "
        v-show="active == indx"
      ></div>
    </template>
    <Dialog :info="firmList" @open="handleGs" @close="handleCloseFirm"/>
  </dv-full-screen-container>
</template>

<script>
import Header from "@/components/Header/header.vue";
import Footer from "@/components/Footer/footer.vue";
import Left from "./left";
import Info from "./info";
import Right from "./right";
import Dialog from "./dialog.vue"
export default {
  components: {
    Header,
    Footer,
    Left,
    Right,
    Info,
    Dialog
  },
  data() {
    return {
      positionsLeft: "",
      positonsTop: "",
      show: true,
      active: null,
      firmShow: false,
      rightShow: false,
      cAct: "",
      // firmList: [
      //   { name: "浙江青蜥科技有限公司" },
      //   { name: "公司1" },
      //   { name: "公司2" },
      //   { name: "公司3" },
      // ],
      firmLists: {}, //公司详细信息
      firmList: [],
      companyList: [
        { name: "", left: "15.5%", top: "0%", id: "1",buildingNumber: '6' },
        { name: "", left: "30%", top: "11%", id: "2",buildingNumber: '5' },
        { name: "", left: "35.5%", top: "5%", id: "3",buildingNumber: '4' },
        { name: "", left: "51%", top: "26%", zIndex: 4, id: "4",buildingNumber: '1' },
        { name: "", left: "56.5%", top: "18%", zIndex: 5, id: "5",buildingNumber: '2' },
        { name: "", left: "61.5%", top: "11%", zIndex: 6, id: "6",buildingNumber: '3' },
        { name: "", left: "61.5%", top: "3%", id: "7",buildingNumber: '7' },
      ],
      positonList: [{}],
    };
  },
  created() {
    // this.getList();
    this.basicBuilding();
  },
  methods: {
    async handleCompany(idx, item) {
      this.active = idx;
      this.positionsLeft = item.left;
      this.positonsTop = item.top;
      this.firmList = [];
      let res = await this.$api.home.queryParkEnterprise(item.id);
      if (res.code == 200) {
        this.firmShow = true;
        this.firmList = res.data;
      }
    },
    //所有园区企业
    // async getList() {
    //   let res = await this.$api.home.queryParkEnterprise();
    //   if (res.code == 200) {
    //     for (var k = 0, length = res.data.length; k < length; k++) {
    //       // alert(array[k]);
    //       this.companyList[k].id = res.data[k].companyId;
    //       this.companyList[k].name = res.data[k].companyName;
    //     }

    //     // console.log(this.companyList, 99);
    //   }
    // },
    //查询所有楼宇
    async basicBuilding() {
      let res = await this.$api.home.basicBuilding();
      if (res.code == 200) {
        this.companyList.forEach(it => {
          let obj = res.data.find(item => item.buildingNumber == it.buildingNumber)
          it.id = obj.buildingId
          it.name = obj.buildingName
        })
      }
    },
    //公司的各个信息
    async handleGs(id) {
      this.cAct = id;
      this.show = false;
      this.rightShow = false;
      let res = await this.$api.home.queryParkEnterpriseId(id);
      if (res.code == 200) {
        this.firmLists = res.data;
      }
      let that = this;
      setTimeout(function () {
        that.rightShow = true;
      }, 300);
    },
    handleCloseFirm() {
      this.firmShow = false;
      this.active = null;
      this.show = true;
      this.rightShow = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.bg {
  background-image: url("../../assets/home/bg.png");
  /* background-color: #000; */
  background-position: center left;
  background-size: 100% auto;
}
.div {
  z-index: 1000;
  font-size: 0.4rem;
  font-family:YouSheBiaoTiHei;
  font-weight: bold;
  //缕空字
  // -webkit-text-fill-color: #00d8ff;
  -webkit-text-fill-color: #1f96a4;
  -webkit-text-stroke-color: rgba(255, 255, 255, 0.5);
  -webkit-text-stroke-width: 1px;
  position: absolute;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-left: -.3rem;
  span {
    z-index: 1000;
  }
  p {
    width: 0.8rem;
    height: 0.8rem;
    text-align: center;
    img {
      width: 100%;
      height: 100%;
    }
  }
}
.company1 {
  left: 25%;
  top: 29%;
}
.company2 {
  left: 39.5%;
  top: 41%;
}
.company3 {
  left: 45%;
  top: 34%;
}
.company4 {
  left: 61%;
  top: 56%;
}
.company5 {
  left: 66%;
  top: 48%;
}
.company6 {
  left: 71%;
  top: 40%;
}
.company7 {
  left: 70.5%;
  top: 32.5%;
}
.positionName {
  background-size: 100% auto;
}
.position1 {
  position: fixed;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  width: 4.6rem;
  height: 3.175rem;
  left: 17%;
  top: 26%;
  background-image: url("../../assets/home/gong1.png");
  z-index: 10;
}
.position2 {
  position: fixed;
  width: 5.275rem;
  height: 4.3rem;
  left: 29%;
  top: 32.5%;
  // left: 419px;
  // top: 260px;
  background-image: url("../../assets/home/gong2.png");
  z-index: 10;
}
.position3 {
  position: fixed;
  width: 4.65rem;
  height: 3.375rem;
  left: 36.7%;
  top: 26.9%;
  background-image: url("../../assets/home/gong3.png");
  z-index: 10;
}
.position4 {
  position: fixed;
  width: 6.35rem;
  height: 5rem;
  left: 48.5%;
  top: 45.8%;
  background-image: url("../../assets/home/gong4.png");

  z-index: 10;
}
.position5 {
  position: fixed;
  width: 5.5rem;
  height: 4.3rem;
  left: 56.7%;
  top: 39.3%;
  background-image: url("../../assets/home/gong5.png");
  z-index: 10;
}
.position6 {
  position: fixed;
  width: 5.375rem;
  height: 4rem;
  left: 61.5%;
  top: 33%;
  background-image: url("../../assets/home/gong6.png");
  z-index: 10;
}
.position7 {
  position: fixed;
  width: 2.375rem;
  height: 1.725rem;
  left: 67%;
  top: 28.5%;
  background-image: url("../../assets/home/gong7.png");
  z-index: 10;
}


</style>