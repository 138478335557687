<template>
  <div>
    <div class="rightBox">
    <div>
      <div class="right" v-show="qiyeShow">
        <h5>园区企业</h5>
        <img
          src="@/assets/home/zhankai.png"
          alt=""
          class="imgs"
          @click="handleQiye"
        />
        <h6>{{ title }}</h6>
        <ul>
          <li v-for="(item, index) in list" :key="index">
            <div>
              <span
                class="imgg"
                :style="{ backgroundImage: 'url(' + item.srcs + ')' }"
              />
              <img :src="item.src" alt="" />
            </div>
            <div>
              <p class="p1" :style="{ fontSize: item.size, color: item.color }">
                <template v-if="index != 4">
                  <countTo :startVal='startVal' :endVal='item.number ? item.number:0' :duration='3000'  style='font-weight: 700;font-size: 0.35rem;'></countTo>
                </template>
                <template v-else>
                  {{item.number }}
                </template>
                <span>{{ item.unit }}</span>
              </p>
              <p>{{ item.price }}</p>
            </div>
          </li>
        </ul>
        <div class="xian"></div>
        <div class="qiye" v-if="imgList.length > 0">
          <!-- <img src="@/assets/home/qiye.png" alt="" /> -->
          <el-carousel height="1.7rem" arrow="never" indicator-position="none" :interval="5000">
            <el-carousel-item v-for="(item, index) in imgList" :key="index">
              <img :src="item" alt="" />
            </el-carousel-item>
          </el-carousel>
        </div>
        <div class="contentBox">
          <h6>公司简介:</h6>
          <div class="content scrollbar">
            {{ content }}
          </div>
        </div>
      </div>
      <div
        class="right"
        v-show="!qiyeShow"
        style="height: 0.65rem"
        :style="{ backgroundImage: 'url(' + imgSrc + ')' }"
      >
        <h5>园区企业</h5>
        <img
          src="@/assets/home/zhankai.png"
          alt=""
          class="imgs"
          @click="handleQiye"
          style="transform: rotate(180deg)"
        />
      </div>
    </div>
  </div>
  
  </div>

</template>

<script>
import moment from "moment"
import Swiper from "swiper";
import countTo from "vue-count-to"
import "swiper/css/swiper.min.css";
export default {
  props: ["lists"],
  components: {
    countTo
  },
  data() {
    return {
      content: "",
      title: "",
      qiyeShow: true,
      imgSrc: require("../../../assets/home/titleBg.png"),
      list: [
        {
          number: "30366",
          unit: "万元",
          price: "年产值",
          color: "color",
          size: "0.35rem",
          src: require("../../../assets/home/icon7.png"),
          srcs: require("../../../assets/home/quan1.png"),
        },
        {
          number: "300",
          unit: "人",
          price: "员工人数",
          color: "color",
          size: "0.35rem",
          src: require("../../../assets/home/icon8.png"),
          srcs: require("../../../assets/home/quan1.png"),
        },
        {
          number: "16",
          unit: "个",
          price: "知识产权",
          color: "#00ffe5",
          size: "0.35rem",
          src: require("../../../assets/home/icon9.png"),
          srcs: require("../../../assets/home/quan2.png"),
        },
        {
          number: "2000",
          unit: "㎡",
          price: "占地面积",
          color: "#00ffe5",
          size: "0.35rem",
          src: require("../../../assets/home/icon10.png"),
          srcs: require("../../../assets/home/quan2.png"),

          bottom: "0.1rem",
        },
        {
          number: "2001-08-03",
          unit: "",
          price: "成立时间",
          color: "#9cffa3",
          size: "0.24rem",
          src: require("../../../assets/home/icon11.png"),
          srcs: require("../../../assets/home/quan3.png"),
        },
        {
          number: "5650",
          unit: "万",
          price: "注册资本",
          color: "#9cffa3",
          size: "0.35rem",
          src: require("../../../assets/home/icon12.png"),
          srcs: require("../../../assets/home/quan3.png"),
        },
      ],
      imgList: []
    };
  },
  watch: {
    lists(val) {
      this.content = this.lists.briefIntroduction;
      this.title = this.lists.companyName;
      this.list[0].number = this.lists.annualOutputValue;
      this.list[1].number = this.lists.peopleNumber;
      this.list[2].number = this.lists.intellectualPropertyRights;
      this.list[3].number = this.lists.landArea;
      this.list[4].number = moment(this.lists.established).format("YYYY-MM-DD");
      this.list[5].number = this.lists.registeredCapital;
      if(this.lists.image) {
        this.imgList = this.lists.image.split(',')
      }else {
        this.imgList = []
      }
    },
  },
  computed: {},
  created() {
    this.content = this.lists.briefIntroduction;
    this.title = this.lists.companyName;
    this.list[0].number = this.lists.annualOutputValue;
    this.list[1].number = this.lists.peopleNumber;
    this.list[2].number = this.lists.intellectualPropertyRights;
    this.list[3].number = this.lists.landArea;
    this.list[4].number = moment(this.lists.established).format("YYYY-MM-DD");
    this.list[5].number = this.lists.registeredCapital;
  },
  mounted() {
    this.initSwiper()
    if(this.lists.image) {
      this.imgList = this.lists.image.split(',')
    }else {
      this.imgList = []
    }
  },
  methods: {
    handleQiye() {
      this.qiyeShow = !this.qiyeShow;
    },
    initSwiper() {
      setTimeout(function () {
        new Swiper("#swiper", {
          direction: "horizontal",
          slidesPerView: 1,
          loop: true,
          autoplay: {
            delay: 5000,
            disableOnInteraction: false,
          },
        });
      }, 1000);
    }
  },
};
</script>

<style lang="scss" scoped>
.rightBox {
  position: absolute;
  top: 1rem;
  right: 0.3rem;
  color: #fff;
  .right {
    width: 5.4rem;
    height: 9.175rem;
    background-image: url("../../../assets/home/qiyeBg.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    .imgs {
      position: absolute;
      right: 0.3rem;
      top: 0.35rem;
    }
    h5 {
      font-size: 0.3rem;
      font-family: "YouSheBiaoTiHei";
      padding-left: 0.55rem;
      padding-top: 0.12rem;
    }
    h6 {
      font-size: 0.25rem;
      font-weight: 700;
      margin-top: 0.45rem;
      padding-left: 0.35rem;
    }
    ul {
      display: flex;
      padding: 0.05rem 0.1rem 0 0.1rem;
      flex-wrap: wrap;
      li {
        position: relative;
        height: 1rem;
        display: flex;
        align-items: center;
        width: 50%;
        p {
          font-size: 0.1rem;
          color: #88b1d0;
        }
        .p1,
        .p2,
        .p3 {
          font-weight: 700;
          font-size: 0.35rem;
          span {
            font-size: 0.1rem;
          }
        }
        .p1 {
          color: #00e4ff;
        }
        .p2 {
          color: #00ffe5;
        }
        .p3 {
          color: #9cffa3;
        }
        >div{
          position: relative;
        }
        div>img {
          width: 1.2rem;
        }
      }
    }
    .xian {
      width: 4.65rem;
      height: 1px;
      background: #00d8ff;
      opacity: 0.48;
      margin: auto;
      margin-bottom: 0.35rem;
    }
    .qiye {
      width: 4.7rem;
      height: 1.7rem;
      background-image: url("../../../assets/home/qiyeBg1.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      margin: auto;
      img {
        width: 100%;
        height: 1.7rem;
        object-fit: cover;
      }
    }
    .contentBox {
      width: 5rem;
      padding: 0 0rem 0 0.5rem;
      font-size: 0.2rem;
      margin-top: 0.1rem;
      h6 {
        font-size: 0.25rem;
        margin-bottom: 0.1rem;
        text-align: left;
        padding-left: 0;
      }
      .content {
        letter-spacing: 0.02rem;
        line-height: 0.25rem;
        text-indent: 2em;
        height: 1.8rem;
        overflow: auto;
      }
    }
  }
  .imgg {
    width: 1.2rem;
    height: 1.2rem;
    display: inline-block;
    background-image: url("../../../assets/home/quan1.png");
    background-size: 100% auto;
    background-repeat: no-repeat;
    position: absolute;
    top: 0;
    left: 0;
    // animation: rturn 15s linear infinite;
  }
}
@keyframes turn {
  0% {
    -webkit-transform: rotate(0deg);
  }
  25% {
    -webkit-transform: rotate(-90deg);
  }
  50% {
    -webkit-transform: rotate(-180deg);
  }
  75% {
    -webkit-transform: rotate(-270deg);
  }
  100% {
    -webkit-transform: rotate(-360deg);
  }
}
.rturn {
  animation: rturn 4s linear infinite;
}
@keyframes rturn {
  0% {
    -webkit-transform: rotate(0deg);
  }
  25% {
    -webkit-transform: rotate(90deg);
  }
  50% {
    -webkit-transform: rotate(180deg);
  }
  75% {
    -webkit-transform: rotate(270deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
</style>