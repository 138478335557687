<template>
  <div class="leftBox">
    <div>
      <div class="left-top" v-if="brief">
        <h5>园区简介</h5>
        <img
          src="@/assets/home/zhankai.png"
          alt=""
          class="imgs"
          @click="handleImg(0)"
        />
        <div class="imgBox">
          <!-- 监听change事件,切换自定义指示器的样式，当只有两项数据时不显示默认的指示器 -->
          <el-carousel
            height="2.725rem"
            :interval="5000"
            trigger="click"
            @change="changeCarousel"
            ref="carouselTax"
            :indicator-position="imgList.length === 2 ? 'none' : 'outside'"  
          >
            <el-carousel-item v-for="(item, index) in imgList" :key="index">
              <img :src="item" alt="" />
            </el-carousel-item>
          <!-- 复制一遍，当只有两项时才显示 -->
            <div v-if="imgList.length===2">
            <el-carousel-item v-for="(item, index) in imgList" :key="index">
              <img :src="item" alt="" />
            </el-carousel-item>
            </div>
          </el-carousel>
          <ul class="el-carousel__indicators el-carousel__indicators--horizontal el-carousel__indicators--outside"
          v-if="imgList.length===2">
          <li :class="index === indexActive?'is-active':''" class="el-carousel__indicator el-carousel__indicator--horizontal"
                v-for="(group,index) in imgList" :key="index"  @click="handleCarousel(index)">  <!-- 点击指示器，切换幻灯片的索引 -->
              <button class="el-carousel__button"></button>
            </li>
          </ul>
        </div>
        <div class="contentBox">
          <h6>园区简介:</h6>
          <div class="content">
            {{ info.parkIntroduction }}
            <!-- 着眼于产业定位、产业服务，统筹土地开发、园区
            建设、园区育婴管理与服务等业务一体，为集团开展科
            技创新、产业投资、科技金融等业务提供优质载体空间
            和优厚的双创主体资源支撑。 -->
          </div>
        </div>
      </div>
      <div
        class="left-top"
        v-if="!brief"
        style="height: 0.6185rem"
        :style="{ backgroundImage: 'url(' + imgSrc + ')' }"
      >
        <h5>园区简介</h5>
        <img
          src="@/assets/home/zhankai.png"
          alt=""
          class="imgs"
          @click="handleImg(1)"
          style="transform: rotate(180deg)"
        />
      </div>
    </div>
    <div>
      <div class="left-bottom" v-if="survey">
        <h5>园区概况</h5>
        <img
          src="@/assets/home/zhankai.png"
          alt=""
          class="imgs"
          @click="handleSurvey"
        />
        <ul>
          <li
            v-for="(item, index) in liList"
            :key="index"
            :style="{ backgroundImage: 'url(' + item.src + ')' }"
          >
            <div class="liBox">
              <div class="title">
                <countTo :startVal='startVal' :endVal='item.number ? item.number :0' :duration='3000' style="font-size: .3rem;color: #00e4ff;font-family: YouSheBiaoTiHei;opacity: 1;"></countTo>
                <span class="unit">{{ item.unit }}</span>
              </div>
              <div>{{ item.name }}</div>
            </div>
          </li>
        </ul>
      </div>
      <div
        class="left-bottom"
        style="height: 0.6125rem"
        :style="{ backgroundImage: 'url(' + imgSrc + ')' }"
        v-if="!survey"
      >
        <h5>园区概况</h5>
        <img
          src="@/assets/home/zhankai.png"
          alt=""
          class="imgs"
          @click="handleSurvey"
          style="transform: rotate(180deg)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import countTo from "vue-count-to"
export default {
  components: {
    countTo
  },
  data() {
    return {
      new_imgs_length: 0,
      cur_index: 0,
      imgList: [],
      brief: true,
      survey: true,
      imgSrc: require("../../../assets/home/titleBg.png"),
      liList: [
        {
          name: "园区占地面积",
          number: '',
          unit: "㎡",
          src: require("../../../assets/home/1.png"),
        },
        {
          name: "建筑面积",
          number: '',
          unit: "㎡",
          src: require("../../../assets/home/2.png"),
        },
        {
          name: "楼宇数",
          number: '',
          unit: "幢",
          src: require("../../../assets/home/3.png"),
        },
        {
          name: "生产总值",
          number: '',
          unit: "万元",
          src: require("../../../assets/home/4.png"),
        },
      ],
      
      info: "",
    };
  },
  computed: {},
  created() {
    this.getBaseInfo();
  },
  methods: {
    changeCarousel(a){
      if (this.imgList.length===2){
        a===2?this.indexActive=0:a===3?this.indexActive = 1:this.indexActive=a
      }else {
        this.indexActive = a
      }
    },
    handleCarousel(index){
      this.$refs.carouselTax.setActiveItem(index)
    },

    handleImg() {
      this.brief = !this.brief;
    },
    handleSurvey() {
      this.survey = !this.survey;
    },
    async getBaseInfo() {
      let res = await this.$api.home.getBaseInfo();
      if (res.code == 200) {
        this.liList[0].number = res.data.landArea;
        this.liList[1].number = res.data.constructionArea;
        this.liList[2].number = res.data.totalBuildingNumber;
        this.liList[3].number = res.data.totalOutputValue;
        this.info = res.data;
        this.imgList = res.data.parkPicture.split(",");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.leftBox {
  position: absolute;
  top: 1rem;
  left: 0.22rem;
  color: #fff;
  .left-top {
    width: 5.2625rem;
    height: 5.925rem;
    background-image: url("../../../assets/home/jianjieBg.png");
    background-repeat: no-repeat;
    position: relative;
    margin-bottom: 0.3rem;
    background-size: 100% 100%;
    .imgBox {
      width: 4.7rem;
      height: 2.725rem;
      background-image: url("../../../assets/home/jianjieBanner.png");
      background-repeat: no-repeat;
      background-size: 100% 100%;
      margin: auto;
      margin-top: 0.4rem;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .contentBox {
      width: 5rem;
      padding: 0 0rem 0 0.4rem;
      font-size: 0.15rem;
      margin-top: 0.3rem;
      h6 {
        font-size: 0.2rem;
        margin-bottom: 0.15rem;
        text-align: left;
      }
      .content {
        letter-spacing: 0.02rem;
        line-height: 0.25rem;
        text-indent: 2em;
        font-size: 0.2rem;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 5;
        overflow: hidden;
      }
    }
  }
  .imgs {
    position: absolute;
    right: 0.2rem;
    top: 0.3rem;
  }
  h5 {
    font-size: 0.3rem;
    font-family: "YouSheBiaoTiHei";
    padding-left: 0.55rem;
    padding-top: 0.12rem;
  }

  .is-active{
          button{
            background: #c0c4cc;
          }
        }

  .left-bottom {
    width: 5.2625rem;
    height: 3.7rem;
    background-image: url("../../../assets/home/yuanqu.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    position: relative;
    ul {
      display: flex;
      padding: 0.5rem 0.1rem 0.5rem 0.25rem;
      flex-wrap: wrap;
      li {
        position: relative;
        width: calc(50% - 0.15rem);
        height: 1rem;
        background-repeat: no-repeat;
        margin-bottom: 0.2rem;
        background-size: 100% 100%;
        .liBox {
          position: absolute;
          left: 35%;
          top: 15%;
          font-size: 0.175rem;
          .title {
            font-size: 0.3rem;
            color: #00e4ff;
            font-family: "YouSheBiaoTiHei";
            position: relative;
            white-space: nowrap;
            span.unit {
              font-family: "";
              font-weight: 400;
              font-size: 0.14rem;
              color: #ffffff;
              margin-left: -.1rem;
              opacity: 0.5;
              white-space: nowrap;
            }
          }
        }
      }
    }
  }
}
</style>