<template>
  <div>
    <div class="right" v-if="inform">
      <div class="tit" @click="handleInform">
        <h5>通知公告</h5>
        <img src="@/assets/home/zhankai.png" alt="" class="imgs" />
      </div>
      <ul>
        <li
          v-for="(item, index) in keepList"
          :key="index"
          :class="index % 2 ? 'liss' : 'lis'"
          @click="handleShowContent(item)"
        >
          <span
            >【{{
              item.newsAnnouncementType == 1 ? "通知公告" : "系统消息"
            }}】</span
          >{{ item.newsAnnouncementTitle }}
        </li>
      </ul>
    </div>
    <div
      class="right"
      v-if="!inform"
      style="height: 0.58rem"
      :style="{ backgroundImage: 'url(' + imgSrc + ')' }"
    >
      <div class="tit" @click="handleInform">
        <h5>通知公告
          <img
          src="@/assets/home/zhankai.png"
          alt=""
          class="imgs"
          style="transform: rotate(180deg)"
        />
        </h5>
        
      </div>
    </div>
    <div class="dialog info" v-if="dialogShow" >
      <h5>{{info.newsAnnouncementTitle}}
      <div class="close" @click="dialogShow = false">
        <img src="@/assets/close-2.png" alt="" />
      </div>
      </h5>
      <div class="text scrollbar">
        <p>
          {{info.content}}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      inform: false,
      imgSrc: require("../../../assets/home/titleBg.png"),
      keepList: [],
      info: '',
      dialogShow: false
    };
  },
  computed: {},
  created() {
    this.getList();
  },
  methods: {
    handleShowContent(item) {
      this.info = item
      this.dialogShow = true
      console.log(item)
    },
    handleInform() {
      this.inform = !this.inform;
    },
    async getList() {
      let data = {
        newsAnnouncementType: 1,
        announcementType: 0,
      };
      let res = await this.$api.partybuilding.conditionalPagingQuery(data);
      if (res.code == 200) {
        this.keepList = res.data.dataList;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.right {
  position: absolute;
  right: 0.22rem;
  top: 1rem;
  width: 5.4rem;
  height: 10rem;
  background-image: url("../../../assets/device/rightBg.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  .tit {
    cursor: pointer;
  }
  h5 {
    color: #fff;
    font-size: 0.3rem;
    font-family: "YouSheBiaoTiHei";
    padding-left: 0.55rem;
    padding-top: 0.1rem;
  }
  .imgs {
    position: absolute;
    right: 0.3rem;
    top: 0.25rem;
  }
  ul {
    overflow-y: auto;
    width: 5.2rem;
    height: 9rem;
    margin-top: 0.4rem;
    padding-left: 0.1rem;
    padding-right: 0.1rem;
    li {
      letter-spacing: 0.01rem;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      cursor: pointer;
      height: 0.7rem;
      line-height: 0.7rem;
      background-size: 100% 100%;
      background-repeat: no-repeat;
      padding-left: 0.1rem;
      font-size: 0.2rem;
      color: #6793b8;
      transition: all .3s;
      span {
        font-weight: 700;
        color: #fff;
      }
      &:hover {
        color:#fff;
      }
    }

    .lis {
      background-image: url("../../../assets/device/li.png");
    }
    .liss {
      background-color: transparent;
    }
  }
  /* 滚动条样式 */
  /*定义滚动条高宽及背景 高宽分别对应横竖滚动条的尺寸*/
  ::-webkit-scrollbar {
    width: 4px;
    height: 10px;
    //   background-color: #00d8ff;
  }

  /*定义滚动条轨道 内阴影+圆角*/
  ::-webkit-scrollbar-track {
    // -webkit-box-shadow: inset 0 0 6px #08101d;
    // border-radius: 10px;
    background-color: #0b1933;
  }

  /*定义滑块 内阴影+圆角*/
  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    // -webkit-box-shadow: inset 0 0 6px #152847;
    background-color: #00d8ff;
  }
}
.info {
    background-image: url("../../../assets/partybuilding/show.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    h5 {
      font-size: 0.3rem;
      font-family: "YouSheBiaoTiHei";
      padding-left: 0.55rem;
      padding-top: 0.12rem;
      color:#FFF;
      height: .6rem;
      position: relative;
    }
      position: absolute;
      top: 3.1rem;
      left: calc(50% - 2.75rem);
      width: 5.2625rem;
      height: 6.925rem;
      z-index: 9999;
      .close {
        position: absolute;
        right: 0.1rem;
        top: 0.2rem;
        cursor: pointer;
        img {
          width: 0.3rem;
          height: 0.3rem;
        }
      }
      .listBox {
        height: 9rem;
        margin-top: 0.2rem;
        overflow: auto;
      }
      p{
        color:#FFF;
        padding: .2rem 0 .2rem .2rem;
        line-height: 1.5;
        font-size: .23rem;
        text-indent: 2em;
      }
      .text {
        height: 6.2rem;
        overflow: auto;
        margin-right: .1rem;
      }
    }
</style>